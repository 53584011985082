<template>
  <v-container class="child-growth-page pt-5 pl-0 pr-0">
    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <v-row no-gutters v-if="is_admin" class="mb-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="create"
              color="primary"
              class="text-none mr-5"
              text
              >{{ $t("Create") }}</v-btn
            >

            <button-menu
              depressed
              :items="admin_menu"
              class="mr-4"
              btn-icon="$vuetify.icons.dotsHorizontal"
            ></button-menu>
          </v-row>

          <v-card flat v-if="!graph_list || graph_list.length < 1">
            <v-card-text class="text-center">
              {{ $t("EmptySize") }}
            </v-card-text>
          </v-card>

          <template v-if="graph_list && graph_list.length > 0">
            <v-card-text class="pr-0 pl-0">
              <div class="text-right mb-3 mr-5">
                <v-menu
                  close-on-click
                  close-on-content-click
                  :disabled="is_loading"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" class="text-none" outlined>
                      <v-icon left small>$vuetify.icons.fasChartArea</v-icon>
                      {{ graph_list_type.text }}
                      <v-icon small right class="ml-1"
                        >$vuetify.icons.fasAngleDown</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-list class="admin-list-menu">
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="(item, index) in graph_types"
                        :key="index"
                        @click="graph_list_type = item"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>

              <vue-frappe
                id="length"
                :labels="dates"
                :title="$t('Length')"
                type="line"
                :height="300"
                :colors="['#457b9d']"
                :tooltipOptions="length_tooltip"
                :dataSets="this.length_items"
              >
              </vue-frappe>
              <br />
              <vue-frappe
                id="weight"
                :labels="dates"
                :title="$t('Weight')"
                type="line"
                :height="300"
                :colors="['#457b9d']"
                :tooltipOptions="weight_tooltip"
                :dataSets="this.weight_items"
              >
              </vue-frappe>
              <br />
              <vue-frappe
                id="headsize"
                :labels="dates"
                :title="$t('HeadSize')"
                type="line"
                :height="300"
                :colors="['#457b9d']"
                :tooltipOptions="length_tooltip"
                :dataSets="this.headsize_items"
              >
              </vue-frappe>
            </v-card-text>

            <v-card-text
              class="text-center caption pt-10"
              v-html="$t('GrowthLink')"
            ></v-card-text>
          </template>

          <form-dialog
            v-on:created="onCreated"
            v-on:updated="onUpdated"
            :child="child"
            ref="dialog"
            v-if="is_admin"
          ></form-dialog>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Weight": "Weight",
    "Length": "Length",
    "Daily": "Daily",
    "Week": "Weekly",
    "Month": "Monthly",
    "HeadSize": "Head circumference",
    "EmptySize": "There is no length and weight to show.",
    "NewInChart": "Chart has changed. Will you reload it?",
    "Reload": "Reload",
    "Create": "Add growth",
    "Growth": "Growth",
    "Child": "Child",
    "Manage": "Manage growth",
    "SuccessCreate": "Growth saved!",
    "GrowthLink": " "
    },
    "sv": {
    "Weight": "Vikt",
    "Length": "Längd",
    "Daily": "Dagligen",
    "Week": "Veckovis",
    "Month": "Månadsvis",
    "HeadSize": "Huvudomfång",
    "EmptySize": "Det finns ingen längd och vikt att visa.",
    "NewInChart": "Diagramen har förändrats. Vill du ladda om dom?",
    "Reload": "Försök igen",
    "Create": "Lägg till tillväxt",
    "Growth": "Tillväxt",
    "Child": "Barn",
    "Manage": "Hantera tillväxt",
    "SuccessCreate": "Tillväxt sparades!",
    "GrowthLink": "<a class='new-link' href='https://www.tillvaxtkurvor.se/' target='_blank' rel='nofollow'>Läs mer om tillväxtkurvor</a>"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { childrenService } from "../../../../_services";
import { VueFrappe } from "vue2-frappe";
const FormDialog = () =>
  import("@/components/diary/dialogs/AdminChildGrowth.vue");

export default {
  name: "child_profile_growth",
  components: {
    VueFrappe,
    "form-dialog": FormDialog,
  },
  metaInfo() {
    return {
      titleTemplate:
        this.child.name +
        "'s " +
        this.$t("Growth") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content:
            this.child.name +
            "'s " +
            this.$t("Growth") +
            " - " +
            this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,
    }),
    dates: function () {
      var unit = this.graph_list.length;
      var data = [];

      for (var i = 0; i < unit; i++) {
        data.push(this.graph_list[i].date);
      }
      return data;
    },
    length_items: function () {
      var unit = this.graph_list.length;
      var data = [];

      for (var i = 0; i < unit; i++) {
        data.push(this.graph_list[i].length);
      }
      return [
        {
          chartType: "line",
          values: data,
        },
      ];
    },
    weight_items: function () {
      var unit = this.graph_list.length;
      var data = [];

      for (var i = 0; i < unit; i++) {
        data.push(this.graph_list[i].weight);
      }
      return [
        {
          chartType: "line",
          values: data,
        },
      ];
    },
    headsize_items: function () {
      var unit = this.graph_list.length;
      var data = [];

      for (var i = 0; i < unit; i++) {
        data.push(this.graph_list[i].headsize);
      }
      return [
        {
          chartType: "line",
          values: data,
        },
      ];
    },
    weight_tooltip: function () {
      var items = this.graph_list;
      return {
        formatTooltipX: (d) =>
          (d + "").toUpperCase() + this.graph_list_type.append,
        formatTooltipY: (d) => d + " " + items[0].weight_format,
      };
    },
    length_tooltip: function () {
      var items = this.graph_list;
      return {
        formatTooltipX: (d) =>
          (d + "").toUpperCase() + this.graph_list_type.append,
        formatTooltipY: (d) => d + " " + items[0].length_format,
      };
    },
    admin_menu: function () {
      var data = [];

      data.push({
        name: this.$t("Manage"),
        icon: "$vuetify.icons.fileDocumentEditOutline",
        to: {
          name: "child_growth_admin",
        },
      });

      return data;
    },
  },
  data: () => ({
    graph_list_type: {},
    graph_types: [],

    graph_list: [],
    is_loading: false,
    error: null,
  }),
  created: function () {
    this.graph_types = [
      { text: this.$t("Daily"), value: "d", append: "" },
      { text: this.$t("Week"), value: "w", append: " " + this.$t("WeeksOld") },
      {
        text: this.$t("Month"),
        value: "m",
        append: " " + this.$t("MonthsOld"),
      },
    ];
    this.graph_list_type = this.graph_types[0];
  },
  methods: {
    ...mapActions({
      create: "diary/child/create",
    }),
    load() {
      var self = this;
      self.is_loading = true;

      childrenService
        .getSizeGraph(self.child.id, self.graph_list_type.value)
        .then(function (response) {
          self.graph_list = response.data;
          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    create() {
      this.$refs.dialog.open();
    },
    onCreated() {
      this.$successNoty(this.$t("SuccessCreate"));
      this.load();
      this.$store.dispatch("diary/timeline/reset", null, { root: true });
    },
    onUpdated() {
      this.load();
      this.$store.dispatch("diary/timeline/reset", null, { root: true });
    },
  },
  watch: {
    graph_list_type: function () {
      this.load();
    },
  },
};
</script>

<style scoped>
.new-link {
  text-decoration: none;
}
.new-link:hover {
  text-decoration: underline;
}
</style>