<template>
  <v-container class="child-timeline pl-0 pr-0 pt-5">
    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <v-card flat v-if="!items || items.length < 1">
            <v-card-text class="text-center">{{ $t("NoPosts") }}</v-card-text>
          </v-card>
          <v-card flat>
            <v-card-text>
              <v-timeline
                align-top
                dense
                v-if="items && items.length"
                class="ml-n7 diary-timeline"
              >
                <v-timeline-item
                  v-for="(item, i) in items"
                  :key="i"
                  :color="item.icon.bgcolor"
                  fill-dot
                  :class="{ deleting: item.deleting }"
                >
                  <router-link slot="icon" :to="item.icon.url">
                    <v-icon
                      :color="item.icon.color"
                      small
                      class="timeline-icon"
                      >{{ item.icon.default_icon }}</v-icon
                    >
                  </router-link>

                  <v-card
                    :color="item.color"
                    class="timeline-item-content"
                    outlined
                  >
                    <v-card-title class="pt-1 pb-1 timeline-item-header">
                      <div class="timeline-time subtitle-1">
                        {{ item.date }}

                        <div class="caption">
                          {{
                            getFormatedChildAgeFromDate(
                              item.child.birth,
                              item.event_date
                            ) | capitalize
                          }}
                        </div>
                      </div>

                      <button-menu
                        icon
                        dark
                        absolute
                        right
                        :items="getItemMenu(item)"
                        btn-icon="$vuetify.icons.dotsHorizontal"
                        btn-class="mt-n4"
                        v-if="user"
                        :is-loading="item.deleting || item.is_abusing"
                      ></button-menu>
                    </v-card-title>

                    <blog-post
                      v-if="item.event_type == feed_types.BLOGPOST"
                      :item="item"
                    ></blog-post>
                    <media-album
                      v-if="item.event_type == feed_types.MEDIAALBUM"
                      :item="item"
                    ></media-album>
                    <child-event
                      v-if="item.event_type == feed_types.CHILDEVENT"
                      :item="item"
                    ></child-event>
                    <child-size
                      v-if="item.event_type == feed_types.CHILDSIZE"
                      :item="item"
                    ></child-size>
                    <own-event
                      v-if="item.event_type == feed_types.OWNEVENT"
                      :item="item"
                    ></own-event>
                    <child-birthday
                      v-if="item.event_type == feed_types.CHILDBIRTHDAYS"
                      :item="item"
                    ></child-birthday>
                    <child-memory
                      v-if="item.event_type == feed_types.CHILDMEMORY"
                      :item="item"
                    ></child-memory>
                  </v-card>
                </v-timeline-item>
              </v-timeline>

              <div v-if="has_more" class="text-xs-center pt-10 pb-10">
                <v-btn
                  block
                  text
                  color="primary"
                  :loading="is_loading"
                  class="text--none"
                  @click="load"
                  >{{ $t("LoadMore") }}</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Timeline": "Timeline",
    "Delete": "Delete post",
    "Abuse": "Abuse post",
    "LoadMore": "Load more",
    "ConfirmDeletePost": "Are you sure you want to delete this post?",
    "NoPosts": "There is no posts to show!",
    "ConfirmAbusePost": "Are you sure you want to abuse this post?",
    "SuccessAbuse": "Abuse sent!",
    "Child": "Child"

    },
    "sv": {
    "Timeline": "Tidslinje",
    "Delete": "Ta bort inlägg",
    "Abuse": "Anmäl inlägg",
    "LoadMore": "Visa mer",
    "ConfirmDeletePost": "Är du säker på att du vill ta bort denna post?",
    "NoPosts": "Det finns inga poster att visa!",
    "ConfirmAbusePost": "Är du säker på att du vill anmäla denna post till granskning?",
    "SuccessAbuse": "Anmälan skickad!",
    "Child": "Barn"

    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { enums, timelineCreator, dateHelpers } from "@/_helpers";
import { childrenService } from "@/_services";

import BlogPost from "@/components/diary/helpers/TimelineItems/BlogPost.vue";
import ChildBirthday from "@/components/diary/helpers/TimelineItems/ChildProfile/ChildBirthday.vue";
import ChildEvent from "@/components/diary/helpers/TimelineItems/ChildProfile/ChildEvent.vue";
import ChildSize from "@/components/diary/helpers/TimelineItems/ChildProfile/ChildSize.vue";
import ChildMemory from "@/components/diary/helpers/TimelineItems/ChildProfile/ChildMemory.vue";
import MediaAlbum from "@/components/diary/helpers/TimelineItems/MediaAlbum.vue";
import OwnEvent from "@/components/diary/helpers/TimelineItems/OwnEvent.vue";

export default {
  name: "child_profile_home",
  metaInfo() {
    return {
      titleTemplate:
        this.child.name +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,
      show_welcome: (state) => state.diary.childprofile.show_welcome,
    }),
  },

  components: {
    "blog-post": BlogPost,
    "child-event": ChildEvent,
    "child-size": ChildSize,
    "media-album": MediaAlbum,
    "own-event": OwnEvent,
    "child-birthday": ChildBirthday,
    "child-memory": ChildMemory,
  },
  data: () => ({
    is_loading: false,
    error: null,
    items: [],
    page: 0,
    has_more: false,

    feed_types: enums.FEEDEVENTS,
  }),
  created: function () {
    this.load();
  },
  methods: {
    ...mapActions({
      delete: "diary/timeline/delete",
      abuse: "diary/timeline/abuse",
    }),
    getFormatedChildAgeFromDate: dateHelpers.getFormatedChildAgeFromDate,
    load() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      childrenService
        .getFeed(self.child.id, self.page + 1)
        .then(function (response) {
          var data = response.data;

          self.items = self.items.concat(
            timelineCreator.formatedTimeline(data.data, self.diary)
          );
          self.page = data.current_page;
          self.has_more = data.has_more;

          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    async deleteItem(item) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeletePost"));

      if (res) {
        self.delete(item.id).then(function () {
          self.items = self.items.filter((post) => post.id !== item.id);
        });
      }
    },
    async abuseItem(item) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmAbusePost"));

      if (res) {
        self.abuse(item.id).then(function () {
          self.$successNoty(self.$t("SuccessAbuse"));
        });
      }
    },

    getTitle() {
      return this.child.name;
    },

    getItemMenu(item) {
      var data = [];
      var self = this;

      if (this.user && !this.is_admin) {
        data.push({
          name: this.$t("Abuse"),
          icon: "$vuetify.icons.alertCircleOutline",
          click: function () {
            self.abuseItem(item);
          },
        });
      }

      if (this.is_admin) {
        data.push({
          name: this.$t("Delete"),
          icon: "$vuetify.icons.trashCanOutline",
          click: function () {
            self.deleteItem(item);
          },
        });
      }

      return data;
    },
  },
  watch: {
    show_welcome: function (newVal, old) {
      if (old && !newVal) {
        this.load();
      }
    },
  },
};
</script>