<template>
  <v-container class="child-timeline pt-5 pb-12">
    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <v-row no-gutters v-if="is_admin">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none text-subtitle-1"
              text
              :to="{ name: 'diary_child_memories_admin' }"
            >
              {{ $t("AddMemory") }}</v-btn
            >
          </v-row>
          <v-row v-if="items && items.length > 0" align="stretch">
            <v-col
              xs="6"
              md="4"
              cols="6"
              v-for="item in items"
              :key="item.url_name"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card :elevation="hover ? 2 : 0" outlined class="fill-height">
                  <v-card
                    :to="{
                      name: 'diary_child_memories_read',
                      params: { type: item.url_name },
                    }"
                    flat
                    class="fill-height"
                  >
                    <v-img
                      class="memory-thumb grey lighten-3"
                      height="140"
                      :src="getImage(item.media)"
                      :title="getTitle(item)"
                      :alt="getTitle(item)"
                    ></v-img>
                    <v-card-title class="child-memory-item-header">{{
                      getTitle(item)
                    }}</v-card-title>
                    <v-card-subtitle class="pb-2">{{
                      item.date
                    }}</v-card-subtitle>
                  </v-card>

                  <button-menu
                    absolute
                    color="secondary"
                    fab
                    x-small
                    dark
                    top
                    right
                    btn-class="mt-6 mr-n2 edit-btn"
                    :items="getAdminMenu(item)"
                    btn-icon="$vuetify.icons.fasPen"
                    btn-icon-small
                    :is-loading="item.is_deleting"
                    v-if="is_admin"
                  ></button-menu>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <v-card flat v-if="!items || items.length < 1">
            <v-card-text class="text-center">
              {{ $t("NoMemories") }}
            </v-card-text>
          </v-card>

          <memory-dialog
            ref="adminMemoryDialog"
            :child="child"
            v-if="is_admin"
          ></memory-dialog>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Memories": "Memories",
    "AddMemory": "Add memories",
    "Edit": "Edit",
    "NoMemories": "There is no memories added yet.",
    "ConfirmDeletePost": "Are you sure, you want to delete this memory?",
    "SuccessDelete": "Post deleted!"
    },
    "sv": {
    "Memories": "Minnen",
    "AddMemory": "Lägg till minnen",
    "Edit": "Redigera",
    "NoMemories": "Det finns inga minnen tillagda ännu",
    "ConfirmDeletePost": "Är du säker på att du vill ta bort denna post?",
    "SuccessDelete": "Posten togs bort!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { childMemories } from "@/_helpers";
const ChildMemoryFormDialog = () =>
  import("@/components/diary/dialogs/ChildMemoryFormDialog.vue");

export default {
  name: "child_profile_home",
  metaInfo() {
    return {
      titleTemplate:
        this.child.name +
        "'s " +
        this.$t("Memories") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content:
            this.child.name +
            "'s " +
            this.$t("Memories") +
            " - " +
            this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,
      items: (state) => state.diary.childprofile.memories.items,
      error: (state) => state.diary.childprofile.memories.error,
      is_loading: (state) => state.diary.childprofile.memories.is_loading,
    }),
  },

  components: {
    "memory-dialog": ChildMemoryFormDialog,
  },
  data: () => ({}),
  created: function () {},
  methods: {
    ...mapActions({
      deleteMemory: "diary/childprofile/deleteMemory",
    }),
    getAdminMenu(item) {
      var data = [];
      var self = this;

      data.push({
        name: this.$t("Edit"),
        icon: "$vuetify.icons.fileDocumentEditOutline",
        click: function () {
          self.openEdit(item);
        },
      });

      data.push({
        name: this.$t("Button.Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: function () {
          self.deleteItem(item);
        },
      });

      return data;
    },

    getTitle(item) {
      if (item.title) return item.title;

      var temp = childMemories.getList().find((p) => p.key == item.url_name);

      if (temp.title) return temp.title;

      return "";
    },
    getDescription(item) {
      if (item.description) return item.description;

      var temp = childMemories.getList().find((p) => p.key == item.url_name);

      if (temp.description) return temp.description;

      return "";
    },
    getImage(media) {
      if (media && media.image && media.image.width_400)
        return media.image.width_400;

      return "";
    },
    openEdit(item) {
      this.$refs.adminMemoryDialog.editItem(item.id);
    },
    async deleteItem(item) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeletePost"));

      if (res) {
        self.$set(item, "is_deleting", true);

        self
          .deleteMemory(item.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDelete"));
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            item.is_deleting = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.child-memory-item-header {
  word-break: normal !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 27px;
  font-size: 20px !important;
}
</style>