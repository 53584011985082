<template>
  <v-container class="pt-5">
    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <v-card flat v-if="!items || items.length < 1">
            <v-card-text class="text-center">{{ $t("NoMedia") }}</v-card-text>
          </v-card>

          <template v-if="items.length > 0">
            <v-row>
              <v-col
                xs="6"
                md="3"
                cols="6"
                sm="4"
                v-for="item in items"
                :key="item.id"
              >
                <image-item
                  :image="item"
                  v-on:updated="onMediaUpdated"
                  v-on:updatedChildren="onMediaChildrenUpdated"
                  :show-album-cover="false"
                ></image-item>
              </v-col>
            </v-row>
            <div v-if="unit_pages > 1" class="text-center">
              <v-pagination
                v-model="page"
                :length="unit_pages"
                :total-visible="7"
              ></v-pagination>
            </div>
          </template>
        </template>

        <error-box :error="error" class="mt-4"></error-box>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Photos",
    "NoMedia": "There is no photos for this child yet!",
    "Child": "Child"
    },
    "sv": {
    "Title": "Bilder",
    "NoMedia": "Det finns inga uppladdade bilder för detta barn ännu!",
    "Child": "Barn"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { childrenService } from "../../../../_services";
import ImageItem from "../../../../components/diary/helpers/AlbumImageItem.vue";

export default {
  name: "child_profile_about",
  metaInfo() {
    return {
      titleTemplate:
        this.child.name +
        "'s " +
        this.$t("Title") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content:
            this.child.name +
            "'s " +
            this.$t("Title") +
            " - " +
            this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,
    }),
  },
  data: () => ({
    page: 1,
    items: [],

    is_loading: false,
    error: null,
    unit_pages: 1,
  }),
  components: {
    "image-item": ImageItem,
  },
  created: function () {
      this.setFromUrl();
      this.loadPhotos();    
  },
  methods: {
    loadPhotos() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      childrenService
        .getPhotosToChild(self.child.id, self.page)
        .then(function (response) {
          self.items = response.data.data;
          self.unit_pages = response.data.unit_pages;
          self.is_loading = false;

          if (self.page > 1 && self.items.length == 0) {
            self.page += -1;
          }
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    onMediaUpdated(media) {
      for (var i = 0; i < this.items.length; i++) {
        var obj = this.items[i];

        if (obj.id == media.id) {
          obj.description = media.description;
          obj.title = media.title;
          obj.backdated_time = media.backdated_time;
          return;
        }
      }
    },
    onMediaChildrenUpdated(media) {
      if (!media || !media.children) return;

      var exists = media.children.find((p) => p.id == this.child.id);

      if (!exists) {
        if (this.unit_pages > 1) this.loadPhotos();
        else {
          for (let i = 0; i < this.items.length; i++) {
            const element = this.items[i];
            if (element.id == media.id) {
              this.items.splice(i, 1);
              return;
            }
          }
        }
      }
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadNews();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });
    },
  },
};
</script>