<template>
  <v-container class="children-page diary-page">
    <v-card min-height="300" elevation="1">
      <v-card-title>
        <h2 class="text-h6">{{ $t("Children") }}</h2>
        <v-spacer></v-spacer>
        <v-btn
          v-if="is_admin"
          text
          color="primary"
          @click="openNewChild"
          class="text-none text-subtitle-1"
        >
          <v-icon left>$vuetify.icons.plus</v-icon>
          {{ $t("AddChild") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            xs="12"
            md="4"
            cols="12"
            v-for="item in children"
            :key="item.id"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                :elevation="hover ? 2 : 0"
                :to="getChildUrl(item.url_name)"
                outlined
              >
                <div class="d-flex flex-no-wrap">
                  <child-avatar
                    class="ma-3"
                    :child="item"
                    :size="80"
                  ></child-avatar>
                  <div>
                    <v-card-title class="headline">{{
                      item.name | capitalize
                    }}</v-card-title>
                    <v-card-subtitle>
                      {{ getFormatedChildAge(item.birth) }}
                      <div class="mt-2" v-if="isBirthday(item.birth)">
                        <v-icon :title="$t('Birthday')" color="purple accent-1"
                          >$vuetify.icons.fasBirthdayCake</v-icon
                        >
                      </div>
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>
            </v-hover>
          </v-col>

          <v-col xs="12" cols="12" class="text-center">
            <div v-if="!children || children.length < 1">
              {{ $t("NoChildren") }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <child-dialog ref="childDialog" v-if="is_admin"></child-dialog>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Children": "Children",
    "NoChildren": "There is no children to show!",
    "Birthday": "Birthday",
    "AddChild": "Add child"
    },
    "sv": {
    "Children": "Barn",
    "NoChildren": "Det finns inga barn att visa.",
    "Birthday": "Födelsedag",
    "AddChild": "Lägg till barn"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
const ChildDialog = () =>
  import("@/components/diary/dialogs/ChildFormDialog.vue");
import ChildAvatar from "@/components/avatars/ChildAvatar.vue";
import * as moment from "moment";
import { dateHelpers } from "@/_helpers";

export default {
  name: "diary_album",
  metaInfo() {
    return {
      titleTemplate:
        this.$t("Children") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content: this.$t("Children") + " | " + this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,

      children: (state) => state.diary.children.list,
    }),
  },
  components: {
    "child-dialog": ChildDialog,
    "child-avatar": ChildAvatar,
  },
  methods: {
    ...mapActions({
      load: "diary/children/load",
    }),
    getFormatedChildAge: dateHelpers.getFormatedChildAge,
    getChildUrl(urlName) {
      return "/" + this.diary.name + "/child/" + urlName;
    },
    isBirthday(date) {
      return moment(date).isSame(moment(), "day");
    },
    openNewChild() {
      this.$refs.childDialog.open(this.diary.name);
    },
  },
};
</script>

<style>
.children-page a {
  color: black;
  text-decoration: none;
}
</style>