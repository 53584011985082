<template>
  <v-container class="pt-5 pl-0 pr-0">
    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <v-card flat v-if="!items || items.length < 1" min-height="300">
            <v-card-text class="text-center">
              {{ $t("NoPosts") }}
              <div v-if="is_admin" class="mt-2 text-center">
                <v-btn
                  :to="getAdminUrl()"
                  color="primary"
                  text
                  small
                  class="text-none"
                  >{{ $t("Create") }}</v-btn
                >
              </div>
            </v-card-text>
          </v-card>

          <template v-if="items && items.length > 0">
            <news-list
              v-on:updated="onUpdated"
              v-on:deleted="onDeleted"
              :news="items"
              flat
              class="child-diary-list"
            ></news-list>

            <v-col
              xs="12"
              cols="12"
              class="text-center mt-4 mb-10"
              v-if="unit_pages > 1"
            >
              <v-pagination
                v-model="page"
                :length="unit_pages"
                :total-visible="7"
              ></v-pagination>
            </v-col>
          </template>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Title": "Diary",
    "NoPosts": "There is no posts to show for this child yet!",
    "Create": "Write a post",
    "Comments": "Comments", 
    "Child": "Child"
    },
    "sv": {
    "Title": "Dagbok",
    "NoPosts": "Det finns inga inlägg skrivna för detta barn ännu!",
    "Create": "Skapa ett inlägg",
    "Comments": "Kommentarer",
    "Child": "Barn"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { childrenService } from "../../../../_services";
import ListType from "../../../../components/diary/helpers/BlogPostList/List.vue";
export default {
  name: "child_profile_diary",
  metaInfo() {
    return {
      titleTemplate:
        this.child.name +
        "'s " +
        this.$t("Title") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content:
            this.child.name +
            "'s " +
            this.$t("Title") +
            " - " +
            this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  components: {
    "news-list": ListType,
  },

  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,
    }),
  },
  data: () => ({
    items: [],
    page: 1,
    unit_pages: 1,
    is_loading: false,
    error: null,
  }),
  created: function () {
    this.setFromUrl();
    this.loadNews();
  },
  methods: {
    loadNews() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      childrenService
        .getBlogPostsToChild(self.child.id, self.page)
        .then(function (response) {
          self.items = response.data.data;
          self.unit_pages = response.data.unit_pages;
          self.is_loading = false;

          if (self.page > 1 && self.items.length == 0) {
            self.page += -1;
          }
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    setFromUrl() {
      this.page = 1;

      if (this.$route.query.page) this.page = parseInt(this.$route.query.page);
    },
    getUrl(item) {
      return "/" + this.diary.name + "/diary/" + item.url_name;
    },
    getAdminUrl() {
      return "/" + this.diary.name + "/diary/new?child=" + this.child.id;
    },
    onDeleted(id) {
      this.removePost(id);
    },
    onUpdated(post) {      
      for (let i = 0; i < this.items.length; i++) {
        const element = this.items[i];
        if (element.id == post.id) {
          if (post.privacy) element.privacy = post.privacy;
          if (post.show_date) element.show_date = post.show_date;
          if (post.categories) element.categories = post.categories;
          if (post.covers) element.covers = post.covers;
          return;
        }
      }
    },
    onMediaChildrenUpdated(post) {
      if (!post || !post.children) return;

      var exists = post.children.find((p) => p.id == this.child.id);

      if (!exists) {
        this.removePost(post.id);
      }
    },
    removePost(id) {
      if (this.unit_pages > 1) this.loadNews();
      else {
        for (let i = 0; i < this.items.length; i++) {
          const element = this.items[i];
          if (element.id == id) {
            this.items.splice(i, 1);
            return;
          }
        }
      }
    },
  },
  watch: {
    $route() {
      this.setFromUrl();
      this.loadNews();
    },
    page() {
      var self = this;

      if (parseInt(self.$route.query.page) == self.page) return;
      self.$router.push({ query: { page: self.page } });
    },
  },
};
</script>

<style>
.child-diary-list .post-covers {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.child-diary-list .diary-post-menu button {
  margin-right: 10px !important;
}
</style>