<template>
  <v-container class="child-progress pt-5 pl-0 pr-0">
    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <v-row no-gutters v-if="is_admin">
            <v-spacer></v-spacer>
            <v-btn
              @click="create"
              color="primary"
              class="text-none mr-5"
              text
              >{{ $t("Create") }}</v-btn
            >

            <button-menu
              depressed
              class="mr-5"
              :items="[
                {
                  name: $t('Manage'),
                  to: {
                    name: 'child_progress_admin',
                    params: { diary: diary.name, child: child.url_name },
                  },
                  icon: '$vuetify.icons.fileDocumentEditOutline',
                },
              ]"
              btn-icon="$vuetify.icons.dotsHorizontal"
              v-if="is_admin"
            ></button-menu>
          </v-row>

          <v-card flat v-if="!items || items.length < 1">
            <v-card-text class="text-center">
              {{ $t("NoPosts") }}
            </v-card-text>
          </v-card>

          <v-card-text v-if="items && items.length > 0">
            <v-timeline dense align-top class="ml-n7 diary-timeline">
              <template v-for="item in items">
                <v-timeline-item
                  class="mb-2 lighten-1"
                  color="#2C6284"
                  :key="item.id"
                  dark
                  large
                  v-if="item.date_header"
                >
                  <template v-slot:icon>
                    <v-icon medium>$vuetify.icons.fasCalendarAlt</v-icon>
                  </template>
                  <div class="text-h6 mt-3">{{ item.date_header }}</div>
                </v-timeline-item>

                <v-timeline-item
                  class="mb-4"
                  color="#457b9d"
                  :key="item.id"
                  v-if="!item.date_header"
                >
                  <template v-slot:icon>
                    <span style="color: white; font-weight: bold">{{
                      item.day
                    }}</span>
                  </template>

                  <v-card outlined>
                    <v-card-title class="pt-2 pb-2 timeline-item-header">
                      <div class="subtitle-1">
                        {{
                          getFormatedChildAgeFromDate(
                            child.birth,
                            item.event_date
                          ) | capitalize
                        }}
                      </div>
                      <v-spacer></v-spacer>

                      <button-menu
                        small
                        dark
                        icon
                        :is-loading="item.deleting || item.is_abusing"
                        :items="getManageMenu(item)"
                        btn-icon="$vuetify.icons.dotsHorizontal"
                        v-if="is_admin"
                      ></button-menu>
                    </v-card-title>
                    <v-card-text class="text--primary pt-3">
                      <h2 class="text-h6 mb-2">
                        {{ item.header | capitalize }}
                      </h2>

                      {{ item.description | capitalize }}</v-card-text
                    >
                  </v-card>
                </v-timeline-item>
              </template>
            </v-timeline>
          </v-card-text>
          <form-dialog
            v-on:created="onCreated"
            v-on:updated="onUpdated"
            :child="child"
            ref="dialog"
            v-if="is_admin"
          ></form-dialog>
        </template>

        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "NoPosts": "There is no progress to show!",
    "Progress": "Progress",
    "Create": "Add progress",
    "Edit": "Edit post",
    "Delete": "Delete post",
    "ConfirmDeleteProgress": "Are you sure you want to delete this post?",
    "SuccessCreate": "Progress created!",
    "SuccessDelete": "Progress deleted!",
    "SuccessUpdate": "Progress updated!",
    "Child": "Child",
    "Manage": "Manage progress"
    },
    "sv": {
    "NoPosts": "Det finns framsteg att visa!",
    "Progress": "Framsteg",
    "Create": "Lägg till framsteg",
    "Edit": "Redigera inlägg",
    "Delete": "Ta bort inlägg",
    "ConfirmDeleteProgress": "Är du säker på att du vill ta bort denna post?",
    "SuccessDelete": "Framsteg togs bort!",
    "SuccessCreate": "Framsteg skapades!",
    "SuccessUpdate": "Framsteg uppdaterades!",
    "Child": "Barn",
    "Manage": "Hantera framsteg"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import { childrenService } from "@/_services";
const FormDialog = () =>
  import("@/components/diary/dialogs/AdminChildProgress.vue");
import * as moment from "moment";
import { dateHelpers } from "@/_helpers";

export default {
  name: "child_profile_progress",
  metaInfo() {
    return {
      titleTemplate:
        this.child.name +
        "'s " +
        this.$t("Progress") +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME,
      meta: [
        {
          property: "og:title",
          content:
            this.child.name +
            "'s " +
            this.$t("Progress") +
            " - " +
            this.diary.title,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,
    }),
  },
  data: () => ({
    items: [],
    is_loading: false,
    error: null,
  }),
  components: {
    "form-dialog": FormDialog,
  },
  created: function () {
    this.load();
  },
  methods: {
    getFormatedChildAgeFromDate: dateHelpers.getFormatedChildAge,
    load() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      childrenService
        .getEvents(self.child.id)
        .then(function (response) {
          self.items = self.fixList(response.data);
          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    async deleteItem(item) {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeleteProgress"));

      if (res) {
        self.$set(item, "is_deleting", true);

        childrenService
          .deleteEvent(item.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDelete"));
            self.load();
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            item.is_deleting = false;
          });
      }
    },
    create() {
      this.$refs.dialog.open();
    },
    edit(item) {
      this.$refs.dialog.edit(item);
    },
    onCreated() {
      this.$successNoty(this.$t("SuccessCreate"));
      this.load();
    },
    onUpdated() {
      this.$successNoty(this.$t("SuccessUpdate"));
      this.load();
    },
    fixList(data) {
      var dateHeader = null;
      var list = [];

      for (var i = 0; i < data.length; i++) {
        var item = data[i];
        var itemDate = moment(item.date);
        var tempHeader = itemDate.format("MMMM YYYY");

        if (dateHeader != tempHeader) {
          dateHeader = tempHeader;
          list.push({ date_header: dateHeader });
        }

        item.color = this.getDottColor(itemDate.format("MM"));
        item.day = itemDate.format("D");

        list.push(item);
      }

      return list;
    },
    getDottColor(month) {
      month = parseInt(month);
      if (month === 1) {
        return "purple lighten-2";
      }
      if (month === 2) {
        return "amber lighten-1";
      }
      if (month === 3) {
        return "cyan lighten-1";
      }
      if (month === 4) {
        return "red lighten-1";
      }
      if (month === 5) {
        return "green lighten-1";
      }
      if (month === 6) {
        return "pink lighten-2";
      }
      if (month === 7) {
        return "indigo lighten-1";
      }
      if (month === 8) {
        return "teal lighten-1";
      }
      if (month === 9) {
        return "lime lighten-1";
      }
      if (month === 10) {
        return "orange lighten-1";
      }
      if (month === 11) {
        return "brown lighten-1";
      }
      return "purple lighten-2";
    },
    getManageMenu(item) {
      var data = [];
      var self = this;

      data.push({
        name: this.$t("Edit"),
        icon: "$vuetify.icons.fileDocumentEditOutline",
        click: function () {
          self.edit(item);
        },
      });

      data.push({
        name: this.$t("Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: function () {
          self.deleteItem(item);
        },
      });

      return data;
    },
    getAdminUrl() {
      return (
        "/" +
        this.diary.name +
        "/child/" +
        this.child.url_name +
        "/progress/admin"
      );
    },
  },
};
</script>