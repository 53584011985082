<template>
  <v-container class="pt-5 pl-0 pr-0 pb-15">
    <v-skeleton-loader :loading="is_loading" type="list-item-avatar-three-line">
      <div>
        <template v-if="!error">
          <v-card flat>
            <v-card-text class="pt-0">
              <v-carousel
                v-model="slider"
                height="400"
                v-if="item.media && item.media.length > 0"
                :show-arrows="item.media.length > 1"
                :hide-delimiters="item.media.length < 2"
              >
                <v-carousel-item
                  v-for="img in item.media"
                  :key="img.id"
                  :src="img.image.width_800"
                >
                </v-carousel-item>
              </v-carousel>

              <template
                v-if="(!item.media || item.media.length == 0) && is_admin"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    :elevation="hover ? 2 : 0"
                    class="grey lighten-3 add-cover"
                    outlined
                    height="200"
                    @click="addCover"
                  >
                    <h2 class="headline text-center mt-15 pt-5">
                      {{ $t("AddCover") }}
                    </h2>

                    <v-overlay absolute :value="cover_is_saving">
                      <v-progress-circular
                        indeterminate
                        size="64"
                      ></v-progress-circular>
                    </v-overlay>
                  </v-card>
                </v-hover>
              </template>
            </v-card-text>

            <v-card-title class="pt-0">
              <h2 class="headline mr-13">{{ getTitle(item) }}</h2>

              <button-menu
                depressed
                small
                right
                :is-loading="is_deleting"
                absolute
                fab
                :items="admin_menu"
                btn-icon="$vuetify.icons.dotsHorizontal"
                btn-class="mt-n4"
                v-if="is_admin"
              ></button-menu>
            </v-card-title>
            <v-card-subtitle>{{ getSubTitle(item) }}</v-card-subtitle>
            <v-card-text class="text--primary text-fix">{{
              item.text
            }}</v-card-text>

            <v-card-text
              v-if="is_admin && !item.text"
              @click="openEdit"
              class="text-center link-color"
              >{{ $t("NoTextAdmin") }}</v-card-text
            >

            <memory-dialog
              ref="adminMemoryDialog"
              :child="child"
              v-if="is_admin"
              v-on:updated="onUpdated"
              v-on:deleted="onDeleted"
            ></memory-dialog>

            <media-picker
              v-if="is_admin"
              v-on:done="selectedMedia"
              ref="mediaPicker"
              :diary="diary.name"
              :max="1"
            ></media-picker>
          </v-card>
        </template>
        <error-page :error="error" v-if="error"></error-page>
      </div>
    </v-skeleton-loader>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "Edit": "Edit post",
    "ConfirmDeletePost": "Are you sure, you want to delete this memory?",
    "SuccessDelete": "Post deleted!",
    "NoTextAdmin": "Add a text to this memory",
    "SuccessUploadCover": "Cover saved!",
    "AddCover": "Add cover"
    },
    "sv": {
    "Edit": "Redigera",
    "ConfirmDeletePost": "Är du säker på att du vill ta bort denna post?",
    "SuccessDelete": "Posten togs bort!",
    "NoTextAdmin": "Skriv och berätta mer om detta minne",
    "SuccessUploadCover": "Omslagsbild sparades!",
    "AddCover": "Välj omslagsbild"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { childMemories } from "@/_helpers";
import { childrenService } from "@/_services";
const ChildMemoryFormDialog = () =>
  import("@/components/diary/dialogs/ChildMemoryFormDialog.vue");
const MediaPicker = () =>
  import("@/components/diary/dialogs/MediaPickerDialog.vue");

export default {
  name: "child_memories_read",
  metaInfo() {
    return {
      titleTemplate: this.getPageTitle(this.item),
      meta: [
        {
          property: "og:title",
          content: this.getPageTitle(this.item),
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      child: (state) => state.diary.childprofile.child,
    }),
    admin_menu: function () {
      var data = [];

      data.push({
        name: this.$t("Edit"),
        icon: "$vuetify.icons.fileDocumentEditOutline",
        click: this.openEdit,
      });

      data.push({
        name: this.$t("Button.Delete"),
        icon: "$vuetify.icons.trashCanOutline",
        click: this.deleteItem,
      });

      return data;
    },
  },
  components: {
    "memory-dialog": ChildMemoryFormDialog,
    "media-picker": MediaPicker,
  },
  data: () => ({
    is_loading: true,
    is_deleting: false,
    error: null,
    item: {},
    memory_type: {},
    slider: null,
    url_name: "",
    is_edit_mode: false,
    cover_is_saving: false,
  }),
  created: function () {
    this.init();
  },
  methods: {
    ...mapActions({
      deleteMemory: "diary/childprofile/deleteMemory",
      addMemoryCover: "diary/childprofile/addMemoryCover",
    }),
    init() {
      var urlName = this.$route.params.type;
      this.memory_type = childMemories.getList().find((p) => p.key == urlName);

      if (!this.memory_type) {
        this.goToMemories();
        return;
      }
      this.url_name = urlName;

      this.load();
    },
    load() {
      var self = this;
      self.error = null;
      self.is_loading = true;

      childrenService
        .getMemory(self.child.id, this.url_name)
        .then(function (response) {
          self.item = response.data;
          self.is_loading = false;
        })
        .catch(function (error) {
          self.error = error;
          self.is_loading = false;
        });
    },
    getTitle(item) {
      if (item.title) return item.title;

      return this.memory_type.title;
    },
    getSubTitle(item) {
      var txt = item.date;

      if (item.place) txt += ", " + item.place;

      return txt;
    },
    getPageTitle(item) {
      return (
        this.getTitle(item) +
        " - " +
        this.child.name +
        " - " +
        this.diary.title +
        " | " +
        process.env.VUE_APP_NAME
      );
    },
    openEdit() {
      this.$refs.adminMemoryDialog.editItem(this.item.id);
    },
    onUpdated() {
      this.init();
    },
    async deleteItem() {
      var self = this;

      const res = await self.$confirm(self.$t("ConfirmDeletePost"));

      if (res) {
        self.is_deleting = true;

        self
          .deleteMemory(self.item.id)
          .then(function () {
            self.$successNoty(self.$t("SuccessDelete"));
            self.goToMemories();
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.is_deleting = false;
          });
      }
    },
    onDeleted() {
      this.goToMemories();
    },
    goToMemories() {
      this.$router.push(
        "/" + this.diary.name + "/child/" + this.child.url_name + "/memories"
      );
    },
    addCover() {
      this.$refs.mediaPicker.open();
    },
    selectedMedia(items) {
      if (items.length > 0) {
        var img = items[0];

        var self = this;
        self.cover_is_saving = true;

        self
          .addMemoryCover({ id: self.item.id, mediaid: img.id })
          .then(function (response) {
            if (!self.item.media) self.item.media = [];

            self.item.media.push(response.data);
            self.cover_is_saving = false;
            self.$successNoty(self.$t("SuccessUploadCover"));
          })
          .catch(function (error) {
            self.$ajaxErrorNoty(error);
            self.cover_is_saving = false;
          });
      }
    },
  },
};
</script>


<style scoped>
.add-cover {
  cursor: pointer;
}
</style>